<template lang="html">
  <div class="header">
    <div class="group">
      <label for="companys">Kompanija: </label>
      <select
        name="type"
        id="type"
        v-model="type"
        @change="onChangeCompany($event)"
      >
        <option
          v-for="company in companiesWithEval"
          v-bind:key="company.name"
          >{{ company.name }}</option
        >
      </select>
    </div>
    <div class="validation">
      {{ validation }}
    </div>

    <div class="ponderi">
      <h2>Procentualni ucinak u ukupnoj oceni (zbirno 100)</h2>
      <h4>Kompetence</h4>
      <input class="form-control" type="number" v-model="kompetenceProcenat" />

      <h4>Licni KPI</h4>
      <input class="form-control" type="number" v-model="licniProcenat" />

      <h4>Kompanijski KPI</h4>
      <input class="form-control" type="number" v-model="kompanijskiProcenat" />
    </div>
  </div>

  <div class="kompetence">
    <h2>Kompetence (zbirno 100)</h2>
    <div class="kpi" v-for="(komp, i) in kompetence" v-bind:key="i">
      <div class="" style="width:100%;">
        <label for="">Naziv:</label>
        <input class="form-control" type="text" v-model="komp.name" />
      </div>

      <div class="" style="width:100%;">
        <label for="">Ponder:</label>
        <input class="form-control" type="number" v-model="komp.ponder" />
      </div>

      <div class="" style="width:100%;">
        <label for="">Komentar:</label>
        <!-- <input
            class="form-control"
            type="text"
            v-model="komp.comment"
            > -->
        <textarea
          class="form-control"
          rows="10"
          style="width:100%;"
          v-model="komp.comment"
        ></textarea>
      </div>

      <button type="button" name="button" @click="ukloniKompetencu(i)">
        ukloni
      </button>
    </div>
    <button type="button" name="button" @click="increaseKompetence">
      Dodaj kompetencu
    </button>
  </div>

  <div class="KompanijskiKpi">
    <h2>Kompanijski Kpi (zbirno 100)</h2>

    <div class="kpi" v-for="(komp, i) in kompanijskiKpi" v-bind:key="i">
      <div class="" style="width:100%;">
        <label for="">Naziv:</label>
        <input class="form-control" type="text" v-model="komp.name" />
      </div>

      <div class="" style="width:100%;">
        <label for="">Ponder:</label>
        <input class="form-control" type="number" v-model="komp.ponder" />
      </div>

      <div class="" style="width:100%;">
        <label for="">Definicija:</label>
        <!-- <input
            class="form-control"
            type="text"
            v-model="komp.comment"
            > -->
        <textarea
          class="form-control"
          rows="10"
          style="width:100%;"
          v-model="komp.comment"
          v-bind:key="i"
        ></textarea>
      </div>

      <button type="button" name="button" @click="ukloniKompanijski(i)">
        ukloni
      </button>
    </div>
    <button type="button" name="button" @click="increaseKompanijskiKpi">
      Dodaj Kpi
    </button>
  </div>

  <button type="button" name="button" @click="submit">Sačuvaj promenu</button>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      lastCompanyEval: 0,
      companiesWithEval: [],
      kompetence: [],
      kompanijskiKpi: [],
      currentCompany: {},
      kompetenceProcenat: 0,
      kompanijskiProcenat: 0,
      licniProcenat: 0,
      validation: "",
    };
  },
  computed: mapGetters(["companys"]),
  methods: {
    ...mapActions(["getCompanies", "setCompanyKpi"]),
    async submit() {
      this.validation = "";
      let kpiData = {
        kompetence: this.kompetence,
        kompanijski: this.kompanijskiKpi,
        companyName: this.currentCompany.name,
        kompetenceProcenat: this.kompetenceProcenat,
        kompanijskiProcenat: this.kompanijskiProcenat,
        licniProcenat: this.licniProcenat,
        index: this.lastCompanyEval,
      };

      if (
        this.kompetenceProcenat +
          this.kompanijskiProcenat +
          this.licniProcenat !=
        100
      ) {
        this.validation = "Zbir procentualnog učinka mora biti 100.";
      }
      let zbirPonder = 0;
      for (var i = 0; i < this.kompetence.length; i++) {
        zbirPonder += this.kompetence[i].ponder;
      }
      if (zbirPonder != 100 && this.kompetenceProcenat != 0) {
        this.validation = "Zbir pondera kompetenci mora biti 100.";
      }
      zbirPonder = 0;
      for (i = 0; i < this.kompanijskiKpi.length; i++) {
        zbirPonder += this.kompanijskiKpi[i].ponder;
      }
      if (zbirPonder != 100 && this.kompanijskiProcenat != 0) {
        this.validation = "Zbir pondera kompanijskih KPI mora biti 100.";
      }
      if (this.validation == "") {
        let res = await this.setCompanyKpi(kpiData);
        console.log("RES: ", res);
        if (res) {
          this.validation = `Sačuvani podaci za kompaniju ${this.currentCompany.name}`;
        } else {
          this.validation = `Neuspelo povezivanje sa bazom (code: PEA_182)`;
        }
      }
    },
    increaseKompetence() {
      let komp = {
        name: "",
        ponder: 0,
      };
      this.kompetence.push(komp);
    },
    increaseKompanijskiKpi() {
      let komp = {
        name: "",
        ponder: 0,
      };
      this.kompanijskiKpi.push(komp);
    },
    ukloniKompetencu(i) {
      this.kompetence.splice(i, 1);
    },
    ukloniKompanijski(i) {
      this.kompanijskiKpi.splice(i, 1);
    },
    onChangeCompany(event) {
      this.kompetence = [];
      this.kompanijskiKpi = [];
      let tempCompany;
      for (var i = 0; i < this.companys.length; i++) {
        if (this.companys[i].name == event.target.value) {
          tempCompany = this.companys[i];
        }
      }
      this.currentCompany = tempCompany;

      this.lastCompanyEval =
        this.currentCompany.performanceEvaluation.length - 1;

      this.kompetenceProcenat = this.currentCompany.performanceEvaluation[
        this.lastCompanyEval
      ].kompetenceProcenat;
      this.kompanijskiProcenat = this.currentCompany.performanceEvaluation[
        this.lastCompanyEval
      ].kompanijskiProcenat;
      this.licniProcenat = this.currentCompany.performanceEvaluation[
        this.lastCompanyEval
      ].licniProcenat;
      //Kompetence
      for (
        i = 0;
        i <
        tempCompany.performanceEvaluation[this.lastCompanyEval].kompetence
          .length;
        i++
      ) {
        let komp = {
          name:
            tempCompany.performanceEvaluation[this.lastCompanyEval].kompetence[
              i
            ].name,
          ponder:
            tempCompany.performanceEvaluation[this.lastCompanyEval].kompetence[
              i
            ].ponder,
          comment:
            tempCompany.performanceEvaluation[this.lastCompanyEval].kompetence[
              i
            ].comment,
        };
        this.kompetence.push(komp);
      }

      //KompanijskiKpi
      for (
        i = 0;
        i <
        tempCompany.performanceEvaluation[this.lastCompanyEval].kompanijski
          .length;
        i++
      ) {
        let komp = {
          name:
            tempCompany.performanceEvaluation[this.lastCompanyEval].kompanijski[
              i
            ].name,
          ponder:
            tempCompany.performanceEvaluation[this.lastCompanyEval].kompanijski[
              i
            ].ponder,
          comment:
            tempCompany.performanceEvaluation[this.lastCompanyEval].kompanijski[
              i
            ].comment,
        };
        this.kompanijskiKpi.push(komp);
      }
    },
  },
  async created() {
    await this.getCompanies();
    for (var i = 0; i < this.companys.length; i++) {
      //  console.log("LENGTH");
      if (this.companys[i].performanceEvaluation.length > 0) {
        this.companiesWithEval.push(this.companys[i]);
      }
    }
    console.log("EVAL COMPS: ", this.companiesWithEval);
  },
};
</script>

<style lang="css" scoped>
.kpi {
  /* display: grid;
  grid-template-columns: 0.5fr 1.5fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". ."
    ". ."
    ". ."
    ". ."; */
  border-style: solid;
  border-color: black;
  border-width: thin;
  padding: 10px;
  margin-bottom: 5px;
}
.validation {
  margin-top: 5px;
  background-color: #00aaaa;
}
.kompetence {
  background-color: #ddddff;
  color: black;
  border-style: solid;
  border-color: black;
  border-width: medium;
  padding: 10px;
  margin-bottom: 5px;
}
.KompanijskiKpi {
  background-color: #ddffdd;
  color: black;
  border-style: solid;
  border-color: black;
  border-width: medium;
  padding: 10px;
  margin-bottom: 5px;
}
.header {
  background-color: #ddffdd;
  color: black;
  border-style: solid;
  border-color: black;
  border-width: medium;
  padding: 10px;
  margin-bottom: 5px;
}
</style>
